<template>
  <div v-if="!isComingSoonCard" class="card-item m-r-25">
    <div class="card-middle-content">
      <h2 class="card-title">{{ title }}</h2>
      <template v-if="loading && $config.whitelabel.features.savingsServices.amounts">
        <div class="ui placeholder">
          <p class="value-placeholder line"></p>
          <p class="description-placeholder line"></p>
        </div>
      </template>
      <template v-else-if="$config.whitelabel.features.savingsServices.amounts">
        <p class="card-value" v-if="value">{{ value | numberSeparator | currency }}</p>
        <p class="card-value card-value-empty" v-else><currency-symbol />0.00</p>
        <p class="card-description">{{ description }}</p>
      </template>
    </div>

    <div class="card-footer">
      <router-link :to="route" class="btn btn-primary">
        {{ routeName }}
      </router-link>
    </div>
  </div>

  <div v-else class="card-item m-r-25 card-item-coming-soon">
    <div class="card-middle-content">
      <h2 class="card-title">{{ title }}</h2>
      <p class="card-description">{{ description }}</p>
    </div>
  </div>
</template>

<script>
import CurrencySymbol from '@/components/CurrencySymbol'

export default {
  name: 'CardSavings',
  components: {
    CurrencySymbol
  },
  props: {
    title: {
      required: true,
      type: String
    },
    description: {
      required: true,
      type: String
    },
    value: {
      required: false,
      type: Number
    },
    routeName: {
      required: false,
      type: String
    },
    route: {
      required: false,
      type: Object
    },
    isComingSoonCard: {
      required: false,
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables';

.card-item {
  padding: 38px 32px;
  display: flex;
  flex-direction: column;
}
.card-middle-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2,
  p {
    margin: 0;
  }
  .card-title {
    font-size: 16px;
    color: var(--color-primary-500);
  }

  .card-description {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-primary-400);
    text-align: center;
  }

  .card-value {
    font-size: 24px;
    color: var(--color-primary-500);
    margin: 5px 0;
    &-empty {
      color: var(--color-primary-400);
    }
  }
  .ui.placeholder {
    position: relative;
  }
  .value-placeholder {
    height: 16px;
    background-color: var(--color-primary-100);
    &:after {
      background-color: var(--color-primary-100);
      content: '';
      display: block;
      background-color: var(--color-primary-100);
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transform: translateX(0);
      animation: placeholder-loader 1.5s ease-in-out infinite;
      opacity: 0.5;
    }
  }
  .description-placeholder {
    height: 8px;
    width: 98px;
    border-radius: 2px;
    background-color: var(--color-primary-100);
  }
}
.card-item {
  .card-middle-content {
    margin-bottom: 13px;
  }
  .card-footer {
    margin-top: 22px;
    padding-bottom: 0;
  }
  .card-item-coming-soon {
    justify-content: center;
  }
}
@keyframes placeholder-loader {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
