<template>
  <div class="page-services">
    <div class="services-wrapper">
      <div class="integration" v-if="isIntegrationAlertVisible">
        <transition name="fade">
          <AlertIntegrations @close-alert="closeAlert" />
        </transition>
      </div>
      <div class="cards-container row">
        <div class="column col-xs-12 col-md-6 col-lg-4" v-if="$config.whitelabel.features.savingsServices.banking">
          <CardSavings
            class="services-card"
            title="Banking"
            description="Current banking fee"
            :value="totalBanking"
            :route="{ name: 'savings-banking' }"
            :loading="loading"
            routeName="explore banking savings"
          />
        </div>
        <div class="column col-xs-12 col-md-6 col-lg-4" v-if="$config.whitelabel.features.savingsServices.fx">
          <CardSavings
            class="services-card"
            title="Foreign exchange (FX)"
            description="Total annual spending"
            :value="totalFx"
            :route="{ name: 'foreign-exchange' }"
            :loading="loading"
            routeName="view more details"
          />
        </div>
        <div class="column col-xs-12 col-md-6 col-lg-4" v-if="$config.whitelabel.features.savingsServices.utilities">
          <CardSavings
            class="services-card"
            title="Utilities"
            description="Total annual spending"
            :value="totalUtilities"
            :route="{ name: 'utilities' }"
            :loading="loading"
            routeName="view more details"
          />
        </div>
        <div class="column col-xs-12 col-md-6 col-lg-4" v-if="$config.whitelabel.features.savingsServices.overdraftFees">
          <CardSavings
            class="services-card"
            title="Overdraft fees"
            description="Total annual spending"
            :value="totalOverdraftFees"
            :route="{ name: 'overdraft-fees' }"
            :loading="loading"
            routeName="view more details"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import { bankGetters, bankMandateListRoutine } from '@/store/modules/bank/routines'
import CardSavings from '@/components/CardSavings'
import AlertIntegrations from '@/components/AlertIntegrations'
import { getBusinessSpending } from '@/api/businessSpending'
import { getBankComparisonStatus, getBankComparisonList } from '@/api/bankComparison'
import { forwardAIIntegrationRoutine, forwardAIGetters } from '@/store/modules/forwardAI/routines'

export default {
  name: 'SavingsServices',
  components: {
    CardSavings,
    AlertIntegrations
  },
  data() {
    return {
      businessSpending: null,
      bankComparisonStatus: null,
      bankComparison: null,
      isIntegrationAlertVisible: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      bankMandateId: bankGetters.BANK_MANDATE_ID,
      companyState: companyGetters.COMPANY_STATE,
      bankMandateList: bankGetters.BANK_MANDATE_LIST,
      forwardAIIntegration: forwardAIGetters.FORWARDAI_INTEGRATION
    }),
    businessSpendingDic() {
      if (!this.businessSpending) return {}
      return this.businessSpending.reduce((acc, spend) => ({ ...acc, [spend.displayName]: spend }), {})
    },
    totalFx() {
      if (!this.businessSpending || !('FX card payments' in this.businessSpendingDic)) return null
      return this.businessSpendingDic['FX card payments'].monthlyAvg * 12
    },
    totalUtilities() {
      if (!this.businessSpending || !('Utilities' in this.businessSpendingDic)) return null
      return this.businessSpendingDic.Utilities.monthlyAvg * 12
    },
    totalOverdraftFees() {
      if (!this.businessSpending || !('Overdraft Fee' in this.businessSpendingDic)) return null
      return this.businessSpendingDic['Overdraft Fee'].monthlyAvg * 12
    },
    totalBanking() {
      if (!this.bankComparison) return null
      return this.bankComparison.currentBankAccountFees || null
    }
  },
  methods: {
    ...mapActions({
      getBankMandateList: bankMandateListRoutine.TRIGGER,
      getForwardAIIntegration: forwardAIIntegrationRoutine.TRIGGER
    }),
    async init() {
      await Promise.all([this.getBankMandateList(this.companyId), this.getForwardAIIntegration(this.companyId)])
      await Promise.all([this.fillBusinessSpending(), this.fillBankComparison()])
      this.checkAlertVisibility()
      this.loading = false
    },
    checkAlertVisibility() {
      const forwardAIIntegrationSuccessState = ['connected', 'ready']

      if (this.$config.whitelabel.features.linkBankAccount || this.$config.whitelabel.features.linkAccountancy) {
        this.isIntegrationAlertVisible =
          !(this.bankMandateList && this.bankMandateList.length) || !forwardAIIntegrationSuccessState.includes(this.forwardAIIntegration && this.forwardAIIntegration.status)
      } else {
        this.isIntegrationAlertVisible = !forwardAIIntegrationSuccessState.includes(this.forwardAIIntegration && this.forwardAIIntegration.status) && this.$config.whitelabel.features.linkAccountancy
      }
    },
    fillBusinessSpending() {
      if (!this.bankMandateList || !this.bankMandateList.length) {
        this.businessSpending = []
        return
      }
      return getBusinessSpending(this.bankMandateId)
        .then(res => {
          this.businessSpending = res.data
        })
        .catch(e => {
          this.businessSpending = []
        })
    },
    async fillBankComparison() {
      if (!this.bankMandateList || !this.bankMandateList.length) {
        this.bankComparison = []
        return
      }
      await this.loadBankComparisonStatus()
      if (this.bankComparisonStatus && this.bankComparisonStatus.status === 'Completed') {
        return this.loadBankComparison()
      }
      this.bankComparison = []
    },
    loadBankComparisonStatus() {
      return getBankComparisonStatus(this.bankMandateId)
        .then(res => {
          this.bankComparisonStatus = res.data
        })
        .catch(e => {
          console.log(e)
          this.bankComparisonStatus = null
        })
    },
    loadBankComparison() {
      return getBankComparisonList(this.bankMandateId)
        .then(res => {
          this.bankComparison = res.data
          return res
        })
        .catch(e => {
          this.bankComparison = []
        })
    },
    closeAlert() {
      this.isIntegrationAlertVisible = false
    },
    trackPageView() {
      this.$ma.trackEvent('PV Banking services')
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.trackPageView()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';

.page-services {
  flex-grow: 1;
  height: 100%;
}

.container {
  margin-left: 0;
}

.services-wrapper {
  padding: 1rem 0;
  .integration {
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: $breakpoint-xs-max) {
    padding: 1rem;
  }
}

.column {
  min-height: 234px;
  margin-bottom: 15px;
}
.column-cash-management,
.column-insurance {
  order: 0;
  @media only screen and (max-width: $breakpoint-md-max) {
    order: 1;
  }
}

.services-card {
  padding: 25px 32px;
  align-items: center;
  /deep/ {
    .btn-primary {
      width: 250px;
    }
  }
}
</style>
